import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import Events from "../components/Events"
import AOS from 'aos';
import 'aos/dist/aos.css';

class EventsPage extends React.Component {

    constructor({ pageContext: { localeData }, data }) {
        super()
        this.eventsPage = data.eventsPage
        this.bgImage = data.bgImage.childImageSharp
        this.events = data.events
        this.localeData = localeData
    }
    
    componentDidMount() {
        AOS.init({
            once: true,
            easing: 'ease-in-out',
            useClassNames: true
        })
    }    

    render() {

        return (
            <Layout localeData={this.localeData} title={this.eventsPage.frontmatter.title}>
                <BookingLine localeData={this.localeData}/>
                <Breadcrumb slug={this.eventsPage.frontmatter.slug} title={this.eventsPage.frontmatter.title} bgImage={this.bgImage} />
                <Events data={this.eventsPage} events={this.events.edges} />
            </Layout>
        );
    }
};
export default withI18next()(EventsPage);

export const eventsPageQuery = graphql`
  query($id: String!, $locale: String!) {
    eventsPage: markdownRemark(id: { eq: $id }){   
        html
        frontmatter {
            slug
            title
        }
    }
    bgImage: file(relativePath: { eq: "events/event_header_bg.jpg" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    events: allMarkdownRemark(
    filter: {frontmatter: {templateKey: {eq: "event"}}, fields: {locale: {eq: $locale}}}
    sort: {fields: frontmatter___date, order: DESC}
    ) {      
        edges {
            node {
                frontmatter {
                    slug
                    title
                    date
                    image {
                        childImageSharp {
                            fluid(maxWidth: 770, quality: 90) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
}`
import React from "react";
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useTranslation } from "react-i18next"
import Heading from "../Heading"
import Moment from 'react-moment'

import './events.scss'

export default ({ data, events }) => {

    const { t } = useTranslation()
    const dateFormat = t('dateFormat')
    const AOS_DELAY = 200

    return(
        <div className="event-list-area pt-95 pb-100">
		    <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading title={t('events-list-title')} subtitle={t('events-list-subtitle')} className="mb-50" />
                    </div>
                </div>
		        <div className="row event-container">
                    {events.map(({node: event}, index) => (
                        <div className="col-lg-6">
                            <div class="event-list-item" data-aos="fade-up" data-aos-delay={(index + 1) * AOS_DELAY}>
                                <div className="img">
                                    <Link to={event.frontmatter.slug}>
                                        <Img sizes={event.frontmatter.image.childImageSharp.fluid} />
                                    </Link>
                                </div>
                                <div className="event-list-content row">
                                    <div class="col">
                                        <h3><Link to={event.frontmatter.slug}>{event.frontmatter.title}</Link></h3>
                                        <div className="event-date">
                                            <Moment format={dateFormat}>
                                                {event.frontmatter.date}
                                            </Moment>
                                        </div>
                                    </div>
                                    <div class="col align-self-end">
                                        <Link to={event.frontmatter.slug} className="primary-btn pull-right">{t('details')} <i className="fa fa-caret-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
		    </div>
        </div>
        
    )}